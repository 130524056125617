export default [
    "white",
    "lightgray",
    "darkgray",
    "black",
    "brown",
    "red",
    "orange",
    "darkyellow",
    "yellow",
    "lightgreen",
    "green",
    "lightblue",
    "blue",
    "darkblue",
    "purple",
    "pink"
];