import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyD-r4g303pe9k0KTh3rAF2k1uMglbZjlcE",
    authDomain: "energywebplace.firebaseapp.com",
    databaseURL: "https://energywebnftsplace.firebaseio.com",
    projectId: "energywebnfts",
    storageBucket: "energywebnfts.appspot.com",
    messagingSenderId: "355811404964",
    appId: "1:355811404964:web:aa014be084dbe15f080a31",
    measurementId: "G-4Y5NX8CT9R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app)